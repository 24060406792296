import Layout from "V2/layout";
import Camera from "V2/camera";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import useScanBackDocument from "hooks/useScanBackDocument";
import { isMobile } from "utils";
import {
  documentImageTypeEnum,
  uploadDocumentImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import { useNavigation } from "utils/onNavigate";
import { getBackDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import {
  browserSupportsWebAuthn,
  startRegistration,
} from "@simplewebauthn/browser";
import {
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import cardBg from "assets/V2/cardBg.png";
import { UAParser } from "ua-parser-js";

interface componentNameProps {}

const BackDriverLicense = (props: componentNameProps) => {
  const [doneStatus, setDoneStatus] = React.useState(false);
  const { navigateToNextPage } = useNavigation();
  const context: any = useContext(UserContext);
  const configuration: any = context?.configuration;
  const { browser } = UAParser(navigator.userAgent);
  const isMobileSafari = browser?.name === "Mobile Safari";
  const [isCompleted, setIsCompleted] = useState(false);
  const pageConfig: any = context?.pageConfiguration;
  const [loader, setLoader] = useState(false);
  const isPasskeySuccess = pageConfig?.type === "passkeySuccess";
  const [supportsPasskey, setSupportsPasskey] = useState(false);
  const [showCorners, setShowCorners] = React.useState(false);
  let loaded = false;

  useEffect(() => {
    const checkWebAuthnSupport = async () => {
      try {
        if ("PublicKeyCredential" in window) {
          const supportsPasskey = browserSupportsWebAuthn();
          setSupportsPasskey(supportsPasskey);
          if (!loaded) {
            loaded = true;
            if (context?.configuration?.isKantara && !isPasskeySuccess) {
              // onAuthenticatePasskey(supportsPasskey);
            }
          }
        } else {
          // WebAuthn is not supported
          setSupportsPasskey(false);
        }
      } catch (error) {
        console.error("Error checking WebAuthn support:", error);
        setSupportsPasskey(false);
      }
    };

    checkWebAuthnSupport();
  }, []);

  const onGeneratePasskey = async () => {
    setLoader(true);
    if (!context?.configuration?.isKantara) {
      if (!supportsPasskey) {
        context.setSuccessMessage("Success! Your account is created");
        setLoader(false);
        navigateToNextPage();
        return;
      }
    }
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      setLoader(false);
      navigateToNextPage();
      return;
    }
    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      setLoader(false);
      navigateToNextPage();
      return;
    }
    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      context.setSuccessMessage("Success! Your account is created");
      setLoader(false);
      navigateToNextPage();
    }
  };

  const onSuccess = async (result: any) => {
    console.log({ result });
    setTimeout(() => {
      setIsCompleted(true);
      setTimeout(() => {
        setDoneStatus(true);
        onGeneratePasskey();
      }, 2000);
    }, 1000);
    const { barcodeData, croppedDocument, croppedBarcode } = result;
    context.setUser({ ...context.user, barcodeData });
    async function uploadDocumentAndUpdateImages() {
      const promises = [];
      // promises.push(
      //   updateDocumentUploadIdWithSession({
      //     documentId: context?.user?.documentId,
      //     sessionToken: context?.tokenParams,
      //     content: JSON.stringify(barcodeData),
      //   })
      // );

      if (croppedDocument) {
        promises.push(
          uploadDocumentImageWithSession({
            sessionToken: context?.tokenParams,
            documentImageType: documentImageTypeEnum.BACK,
            documentId: context?.user?.documentId,
            imageString: croppedDocument,
          })
        );
      }
      if (croppedBarcode) {
        promises.push(
          uploadDocumentImageWithSession({
            sessionToken: context?.tokenParams,
            documentImageType: documentImageTypeEnum.BACK_BARCODE,
            documentId: context?.user?.documentId,
            imageString: croppedBarcode,
          })
        );
      }
      console.log({ promises });
      await Promise.all(promises);
    }
    if (configuration?.uploadToServer) {
      await uploadDocumentAndUpdateImages()
        .then(() => {
          console.log("All uploads and updates completed successfully.");
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
    const backDocumentData = {
      firstName: barcodeData?.barcode_data?.firstName,
      lastName: barcodeData?.barcode_data?.lastName,
      dob: barcodeData?.barcode_data?.dateOfBirth,
      address: {
        addressLine1: barcodeData?.barcode_data?.streetAddress1,
        addressLine2: barcodeData?.barcode_data?.streetAddress2,
        city: barcodeData?.barcode_data?.city,
        state: barcodeData?.barcode_data?.state,
        zipCode: barcodeData?.barcode_data?.postCode,
        country: barcodeData?.barcode_data?.issuingCountry,
        idDocumentNumber: barcodeData?.barcode_data?.customerId,
      },
    };

    context.setUser({ ...context.user, backDocumentData });
  };

  const { scanBackDocument, barcodeStatusCode,
    isMobileDetected,
    docStatusCode, } = useScanBackDocument(
      onSuccess
    ) as any;
  const onCameraReady = () => {
    setShowCorners(true);
    scanBackDocument();
  };
  console.log({ barcodeStatusCode });
  const completed = barcodeStatusCode === 0;
  return (
    <Layout
      camera
      className={`${isMobile && "backDlAnimation"}`}
      innerContainer="max-md:mt-[-78px] max-md:!h-[calc(100vh_-_0rem)]"
    >
      <div className="px-[40px] min-h-full flex flex-col justify-between relative max-md:pt-[40px]">
        {showCorners && (
          <div
            className={`recognition-box1  relative z-10  ${
              completed ? "!w-[50%] !h-[29%]" : "!w-[89%]"
            }`}
          >
            <div
              className={`corner top-left white-border ${
                isCompleted ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner top-right  white-border ${
                isCompleted ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner bottom-left  white-border ${
                isCompleted ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner bottom-right  white-border ${
                isCompleted ? "greenBorder" : ""
              }`}
            ></div>
          </div>
        )}
        <Camera
          onCameraReady={onCameraReady}
          progress={
            isMobileDetected === 0
              ? docStatusCode === 0
                ? 100
                : 0
              : barcodeStatusCode === 0
                ? 100
                : 0
          }
          message={
            isMobileDetected === 0
              ? docStatusCode === 0
                ? "Processing hold still"
                : getBackDocumentStatusMessage(docStatusCode)
              : barcodeStatusCode === 0
                ? "Processing hold still"
                : getBackDocumentStatusMessage(barcodeStatusCode)
          }
          documentScan={true}
        />
        {doneStatus && (
          <div
            className={`${
              doneStatus
                ? `absolute top-0 left-0 w-full flex flex-col items-center justify-center h-[705px] z-[9999999999] backdrop-blur-lg ${isMobileSafari ? 'max-md:h-[calc(100vh_-_4rem)]' : 'max-md:h-[calc(100vh_-_2rem)]'}  ${
                  window.innerWidth < 767
                    ? isMobileSafari
                      ? "[@media_((min-height:700px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                      : "[@media_((min-height:700px)_and_(max-height:730px))]:h-[calc(100vh_-_0rem)] [@media_((min-height:731px)_and_(max-height:750px))]:h-[calc(100vh_-_10rem)] [@media_((min-height:751px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                    : ""
                }`
                : ""
            }`}
          >
            <div
              className="bg-[#161B25B2] w-[100%] h-[100%] flex items-center justify-center successAnimationNewv2"
              style={{
                backgroundImage: `url(${cardBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="text-[25px] absolute top-[62px] text-center left-[0px] z-[99999999] w-full bg-transparent bg-opacity-70 text-[#cbd4eb] py-2 rounded-lg">
              <>
                      <p>🎉 Done!</p>
                      <p className="text-[20px] mt-[10px]">Image Deleted.</p>
                    </>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg-success"
                viewBox="0 0 24 24"
              >
                <g
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle
                    className="success-circle-outline"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <circle
                    className="success-circle-fill"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <polyline
                    className="success-tick"
                    points="17,8.5 9.5,15.5 7,13"
                  />
                </g>
              </svg>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BackDriverLicense;
