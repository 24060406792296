import bloom from "assets/V2/bloom.jpg";
import { Button } from "components/ui/button";
import config from "config";
import { UserContext } from "context/userContext";
import { Loader2 } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import {  useSearchParams } from "react-router-dom";
import { createVerificationSession } from "services/api";
import { getOrganizationFromURL } from "utils";
import { useNavigation } from "utils/onNavigate";

const Landing = () => {
  const {
    setLoginOption,
    setV2SuccessMessage,
    configuration,
    setCheckoutCompleted,
  }: any = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const context: any = useContext(UserContext);
  const [user, setUser] = useState<any>({});
  const [loader, setLoader] = useState(false)
  const [loginLoader, setLoginLoader] = useState(false)
  const [checkoutLoader, setCheckoutLoader] = useState(false)
  
  const onUser = () => {
    const userData = JSON.parse(sessionStorage.getItem("user") || "{}");
    // const user = userData?.firstname ? userData : context?.user
    if (userData?.username || userData?.lastname) {
      setUser(userData);
    }
  };

  useEffect(() => {
    onUser();
  }, []);
  const onLogout = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    sessionStorage.removeItem("uuid");
    sessionStorage.removeItem("user");
    setUser({});
    context?.setUser({})
    onUser();
  };
  const projectName = getOrganizationFromURL();
  const { navigateToNextPage } = useNavigation();
  const isCardFlow = true || ["crown-v2", "vibe"]?.includes(projectName);
  const onSignup = async () => {
    setLoader(true);
    setLoginOption("");
    const payload: any = {
      ...config?.verifyConfig,
    };
    const result: any = await createVerificationSession(payload);
    context.setTokenParams(result?.sessionId);
    const params = `token=${result?.sessionId}${
      searchParams.get("skipAntispoof")
        ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
        : ""
    }${
      searchParams.get("spoof_attempt")
        ? `&spoof_attempt=${searchParams.get("spoof_attempt")}`
        : ""
    }${
      searchParams.get("mf_count")
        ? `&mf_count=${searchParams.get("mf_count")}`
        : ""
    }${
      searchParams.get("mf_count_signin")
        ? `&mf_count_signin=${searchParams.get("mf_count_signin")}`
        : ""
    }${
      searchParams.get("threshold")
        ? `&threshold=${searchParams.get("threshold")}`
        : ""
    }`;
    setLoader(false);
    // window.location.href = `/v2/consent?${params}`;
    navigateToNextPage(params, "/v2/consent", "/");
  };
  const onLogin = async () => {
    setLoginLoader(true);
    setLoginOption("");
    const payload: any = {
      ...config?.verifyConfig,
    };
    const result: any = await createVerificationSession(payload);
    context.setTokenParams(result?.sessionId);
    const params = `token=${result?.sessionId}${
      searchParams.get("skipAntispoof")
        ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
        : ""
    }${
      searchParams.get("spoof_attempt")
        ? `&spoof_attempt=${searchParams.get("spoof_attempt")}`
        : ""
    }${
      searchParams.get("mf_count")
        ? `&mf_count=${searchParams.get("mf_count")}`
        : ""
    }${
      searchParams.get("mf_count_signin")
        ? `&mf_count_signin=${searchParams.get("mf_count_signin")}`
        : ""
    }${
      searchParams.get("threshold")
        ? `&threshold=${searchParams.get("threshold")}`
        : ""
    }`;
    if (!isCardFlow) {
      setLoginOption("prove-checkout");
    }
    setLoginLoader(false);
    // window.location.href = `/v2/login?${params}`;
    navigateToNextPage(params, "/v2/login", "/");
  };

  const onReset = () => {
    setLoginOption("prove-reset");
    navigateToNextPage("", "/v2/login", "/");
  };

  const onCheckout = async () => {
    setCheckoutCompleted(false);
    setV2SuccessMessage("You're Checked Out!");
    setLoginOption("checkoutScreen");
    setCheckoutLoader(true);
    const payload: any = {
      ...config?.verifyConfig,
    };
    const result: any = await createVerificationSession(payload);
    context.setTokenParams(result?.sessionId);
    const params = `token=${result?.sessionId}${
      searchParams.get("skipAntispoof")
        ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
        : ""
    }${
      searchParams.get("spoof_attempt")
        ? `&spoof_attempt=${searchParams.get("spoof_attempt")}`
        : ""
    }${
      searchParams.get("mf_count")
        ? `&mf_count=${searchParams.get("mf_count")}`
        : ""
    }${
      searchParams.get("mf_count_signin")
        ? `&mf_count_signin=${searchParams.get("mf_count_signin")}`
        : ""
    }${
      searchParams.get("threshold")
        ? `&threshold=${searchParams.get("threshold")}`
        : ""
    }`;
    setCheckoutLoader(false);
    navigateToNextPage(params, "/v2/checkout", "/");
  };

  return (
    <div>
      <div className="flex justify-end p-[20px]">
        {user?.username || user?.lastname ? (
          <>
            <div className="relative">
              <div className="flex items-center">
                <div className="relative mr-[10px] w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mt-[-5px] cursor-pointer z-50">
                  <svg
                    className="absolute w-10 h-10 text-gray-400 -left-1"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <p>
                  {user?.username}
                </p>
                {/* <ChevronDown
                  className={`cursor-pointer w-[20px] ml-[5px] ${
                    isMenu && "rotate-180"
                  } transition-all`}
                /> */}
              </div>
            </div>
            <Button
              className="w-[130px] bg-transparent border-2 border-solid border-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-primary hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] ml-[10px]"
              onClick={onLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            {isCardFlow && (
              <button
                className="text-primary hover:underline mr-[20px]"
                onClick={onCheckout}
              >
                {checkoutLoader ? (
                <Loader2 className="animate-spin h-[20px] w-[20px] text-[#000] m-auto" />
              ) : (
                'Checkout with card')}
              </button>
            )}

            <button className="text-primary hover:underline" onClick={onLogin}>
            {loginLoader ? (
                <Loader2 className="animate-spin h-[20px] w-[20px] text-[#000] m-auto" />
              ) : (
                'Sign in')}
            </button>
            <button
              className="ml-[20px] bg-primary text-white px-[30px] py-[4px] rounded-[40px] pb-[6px] scale-110 h-[34px] w-[122px]"
              onClick={onSignup}
              disabled={loader}
            >
              {loader ? (
                <Loader2 className="animate-spin h-[20px] w-[20px] text-[#fff] m-auto" />
              ) : (
                'Sign up'
              )}
            </button>
          </>
        )}
      </div>
      <div
        className={
          isCardFlow
            ? "h-[calc(100vh_-74px)] flex justify-center items-center"
            : ""
        }
      >
        <img
          src={isCardFlow ? configuration?.bannerImage : bloom}
          alt="bloom"
          className={`m-auto ${isCardFlow && "w-[400px] max-md:w-[300px]"}`}
        />
      </div>
      <div className="fixed right-[20px] bottom-[20px] opacity-30">
        <span className="cursor-pointer hover:underline" onClick={onReset}>
          Reset
        </span>
      </div>
    </div>
  );
};

export default Landing;
