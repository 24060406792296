import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { UserContext } from "context/userContext";
import * as React from "react";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import { getFirstRequirement, getOrganizationFromURL, isMobile } from "utils";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import Layout from "V2/layout";
import { useNavigation } from "utils/onNavigate";
import {
  verifyIdWithSession,
  verifySessionTokenV2,
} from "@privateid/cryptonets-web-sdk";
import { issueCredentials } from "services/vc-dock";

let loaded = false;
const OtpScreen = () => {
  let loaded = false;
  const context: any = React.useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const projectName = getOrganizationFromURL();
  const [otp, setOtp] = React.useState("");
  const [wrongOtp, setWrongOtp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [verifyStatus, setVerifyStatus] = React.useState<any>();

  const navigate = useNavigate();

  const handleOtpComplete = (value: string) => {
    setOtp(value);

    if (value === "111111") {
      setWrongOtp(true);
    } else {
      setWrongOtp(false);
    }
  };

  const handleOtpChange = (value: string) => {
    setOtp(value);
    if (wrongOtp) {
      setWrongOtp(false);
    }
  };

  const onVerify = async () => {
    loaded = true;
    await verifyIdWithSession({
      sessionToken: context?.tokenParams,
    });
    const verifyTokenRes = await verifySessionTokenV2({
      sessionToken: context?.tokenParams,
    });
    setVerifyStatus(verifyTokenRes);
  }

  React.useEffect(() => {
    if (!loaded) {
      onVerify();
    }
  }, [])

  const handleSubmit = async () => {
    if (!verifyStatus) {
      onVerify();
      return;
    }
    enum tokenStatus {
      PENDING = "PENDING",
      SUCCESS = "SUCCESS",
      FAILURE = "FAILURE",
      REQUIRES_INPUT = "REQUIRES_INPUT",
    }
    setLoading(true);
    if (projectName === "charms") {
      loaded = false;
      if (verifyStatus.status === tokenStatus.SUCCESS) {
        window.location.replace(verifyStatus?.successUrl);
      } else if (verifyStatus.status === tokenStatus.FAILURE) {
        window.location.replace(verifyStatus?.failureUrl);
      } else if (verifyStatus.status === tokenStatus.REQUIRES_INPUT) {
        getRequirements(verifyStatus?.dueRequirements);
      }
      return;
    }
    if (verifyStatus.status === tokenStatus.SUCCESS) {
      // Success
      loaded = false;
      localStorage.setItem("user", JSON.stringify(verifyStatus));
      navigateToNextPage();
      await issueVC(verifyStatus.user, true);
    } else if (verifyStatus.status === tokenStatus.FAILURE) {
      loaded = false;
      if (pageConfig) {
        navigateToNextPage("", "/v2/failure-screen", "/v2/otp-screen");
      } else {
        navigateWithQueryParams("/v2/failure-screen");
      }
    } else if (verifyStatus.status === tokenStatus.REQUIRES_INPUT) {
      getRequirements(verifyStatus?.dueRequirements);
    } else if (verifyStatus.status === tokenStatus.PENDING) {
      loaded = false;
      if (pageConfig) {
        navigateToNextPage("", "/v2/failure-screen", "/v2/otp-screen");
      } else {
        navigateWithQueryParams("/v2/failure-screen");
      }
    }
    setLoading(false);
  };

  const getRequirements = async (requirement: any) => {
    const requirementStep = await getFirstRequirement(requirement, context);
    loaded = false;
    switch (requirementStep) {
      case "requestSSN9":
        return pageConfig
          ? navigateToNextPage("", "/ssn", "/v2/otp-screen")
          : navigateWithQueryParams("/ssn");
      case "requireResAddress":
        return pageConfig
          ? navigateToNextPage("", "/address", "/v2/otp-screen")
          : navigateWithQueryParams("/address");
      case "requestScanID":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-intro", "/v2/otp-screen")
          : navigateWithQueryParams("/drivers-licence-intro");
      default:
        break;
    }
  };

  const issueVC = async (userId: string, fullInformation: boolean) => {
    try {
      await issueCredentials(userId, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };

  return (
    <Layout logoBackgroundColor={"rgb(22,27,37)"} backgroundColor={"#161b25"}>
      <div className="pt-[40px] max-md:px-[8px] px-[40px] h-[100%] flex flex-col justify-between">
        <div className="flex-grow">
          <Label className="text-[32px] font-[500] text-white block text-center">
            Check your phone
          </Label>
          <Label className="text-[18px]  font-[400] text-white block text-center opacity-70">
            A text message with a link was just sent to the phone ending in
            XXXX. Click on the link in the message to complete registration by
            entering the passcode below
          </Label>

          <PinInput
            length={6}
            initialValue=""
            // secret
            focus
            secretDelay={100}
            type="numeric"
            inputMode="number"
            style={{
              padding: "10px",
              paddingTop: "30px",
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
            
            }}
            ariaLabel={`pin`}
            inputStyle={{
              color: "white",
              borderRadius: "10px",
              borderColor: wrongOtp ? "#E57373" : "gray",
              width: isMobile ? "43px" : "50px",
              height: isMobile ? "50px" : "56px",
              fontSize: isMobile ? "20px" : "24px",
              marginLeft: 10,
              transition: "all .2s",
            }}
            inputFocusStyle={{
              borderColor: wrongOtp ? "#E57373" : "white",

              transform: "scale(1.1)",
            }}
            // onComplete={(value) => {
            //   setOtp(value);

            // }}
            onComplete={handleOtpComplete}
            onChange={handleOtpChange}
            autoSelect={true}
            regexCriteria={/^[0-9]*$/}
          />
          <div className="text-[#f00] text-center ">
            {wrongOtp ? "Wrong OTP entered " : ""}
          </div>
        </div>
        <div className="flex flex-col space-y-4 justify-center items-center pb-8 max-md:pb-[0rem]">
          <Button
            className="w-full text-black bg-white text-[16px] rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </Button>
          <Button
            className="w-[65%] text-[16px] hover:bg-transparent text-[#ffffff] bg-transparent "
            //   onClick={() => {
            //     loadPageConfiguration();
            //   }}
          >
            I did not receive the text
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default OtpScreen;

//when backspace wrong otp wrongError should remove
