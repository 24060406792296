import Layout from "../../layout";
import lock from "../../../assets/lock.svg";
import CameraComponent from "../../components/camera";
import BackButton from "../../../common/components/backButton";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";
import useScanFrontDocument from "../../../hooks/useScanFrontDocumentWithoutPredict";
import { useContext, useState } from "react";
import { UserContext } from "../../../context/userContext";
import {
  closeCamera,
  createDocumentUploadIdTypeEnum,
  createDocumentUploadIdWithSession,
  documentImageTypeEnum,
  uploadDocumentImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import SwitchDeviceSelect from "common/components/switchDeviceSelect";
import { getFrontDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import { useNavigation } from "utils/onNavigate";
import { getUrlParameter, stopCamera } from "utils";
import { ELEMENT_ID } from "constant";
import { pkiEncryptData, updateUserDetails } from "@privateid/ultra-web-sdk-alpha";

type Props = {
  heading?: string;
};

function FrontDlScan(Props: Props) {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const context: any = useContext(UserContext);
  const [completed, setCompleted] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const pageConfig: any = context?.pageConfiguration;
  const configuration: any = context?.configuration;
  const onDocumentSuccess = async () => {
    await closeCamera(ELEMENT_ID);
    await stopCamera();
    setTimeout(() => {
      if (context?.loginOption === "predictAge-Dl") {
        navigateToNextPage("", "/age-success", "/predict-age");
        context?.setLoginOption("");
        return
      } else if (pageConfig) {
        navigateToNextPage();
      } else {
        navigateWithQueryParams("/drivers-licence-back-intro");
      }
    }, 2000);
  };
  const onSuccess = async (
    result:
      | {
          croppedDocument: string;
          croppedMugshot: string;
          inputImage: string;
          portraitConfScore: number;
        }
      | any
  ) => {
    setIsProgress(true);
    setTimeout(() => {
      setCompleted(true);
    }, 1000)
    const {
      inputImage,
      croppedDocument,
      croppedMugshot,
      portraitConfScore: compareScore,
      ocrAge,
    } = result;
    if (context?.loginOption === "predictAge-Dl") {
      context.setUser({
        ...context.user,
        document_age: ocrAge,
        compareScore,
      });
      return;
    }
    const documentId = await createDocumentUploadIdWithSession({
      documentType: createDocumentUploadIdTypeEnum.drivers_license,
      sessionToken: context?.tokenParams,
    });

    context.setUser({ ...context.user, documentId: documentId.id });
    const confScoreUpdatePayload = await pkiEncryptData({
      portrait_conf_score: compareScore?.toFixed(2)?.toString(),
    });

    await updateUserDetails({
      baseUrl: process.env.REACT_APP_API_URL || "",
      token: getUrlParameter("token", "") ||
        "",
      params: confScoreUpdatePayload,
    })
    async function uploadAllImagesAndUpdateUser() {
      const promises = [
        // uploadDocumentImageWithSession({
        //   sessionToken: context?.tokenParams,
        //   documentImageType: documentImageTypeEnum.FRONT,
        //   documentId: documentId.id,
        //   imageString: inputImage,
        // }),
        uploadDocumentImageWithSession({
          sessionToken: context?.tokenParams,
          documentImageType: documentImageTypeEnum.FRONT_CROPPED_DOCUMENT,
          documentId: documentId.id,
          imageString: croppedDocument,
        }),
        uploadDocumentImageWithSession({
          sessionToken: context?.tokenParams,
          documentImageType: documentImageTypeEnum.FRONT_MUGSHOT,
          documentId: documentId.id,
          imageString: croppedMugshot,
        }),
      ];

      await Promise.all(promises);
    }

    if (configuration?.uploadToServer) {
      // Call the function to upload images and update user
      uploadAllImagesAndUpdateUser()
        .then(() => {
          console.log("All uploads and update completed successfully.");
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  };
  const { doScanFrontDocument, resultStatus, scanStatus } = useScanFrontDocument(
    onSuccess,
    () => {},
    context?.user?.enrollEmbeddings
  ) as any;

  const onCameraReady = () => {
    doScanFrontDocument();
  };
  
  return (
    <Layout>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative max-md:p-0">
          <BackButton />
          <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
            <img src={lock} alt="lock" className="mr-[5px]" />
            {pageConfig?.title || "Images are encrypted to preserve privacy"}
          </div>
          <div></div>
        </div>
        <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
          <CameraComponent
            frontDl={true}
            onCameraReady={onCameraReady}
            message={
              scanStatus ||
              (resultStatus === 0
                ? "Processing hold still"
                : getFrontDocumentStatusMessage(resultStatus))
            }
            progress={isProgress ? 100 : 0}
            scanCompleted={completed}
            onSuccess={onDocumentSuccess}
          />
        </div>
      </div>
      <div className="mt-[-40px]">
        <SwitchDeviceSelect />
      </div>
    </Layout>
  );
}

export default FrontDlScan;