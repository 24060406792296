export const States = [
  { label: "Alabama", abbreviation: "AL" },
  { label: "Alaska", abbreviation: "AK" },
  { label: "Arizona", abbreviation: "AZ" },
  { label: "Arkansas", abbreviation: "AR" },
  { label: "California", abbreviation: "CA" },
  { label: "Colorado", abbreviation: "CO" },
  { label: "Connecticut", abbreviation: "CT" },
  { label: "Delaware", abbreviation: "DE" },
  { label: "Florida", abbreviation: "FL" },
  { label: "Georgia", abbreviation: "GA" },
  { label: "Hawaii", abbreviation: "HI" },
  { label: "Idaho", abbreviation: "ID" },
  { label: "Illinois", abbreviation: "IL" },
  { label: "Indiana", abbreviation: "IN" },
  { label: "Iowa", abbreviation: "IA" },
  { label: "Kansas", abbreviation: "KS" },
  { label: "Kentucky", abbreviation: "KY" },
  { label: "Louisiana", abbreviation: "LA" },
  { label: "Maine", abbreviation: "ME" },
  { label: "Maryland", abbreviation: "MD" },
  { label: "Massachusetts", abbreviation: "MA" },
  { label: "Michigan", abbreviation: "MI" },
  { label: "Minnesota", abbreviation: "MN" },
  { label: "Mississippi", abbreviation: "MS" },
  { label: "Missouri", abbreviation: "MO" },
  { label: "Montana", abbreviation: "MT" },
  { label: "Nebraska", abbreviation: "NE" },
  { label: "Nevada", abbreviation: "NV" },
  { label: "New Hampshire", abbreviation: "NH" },
  { label: "New Jersey", abbreviation: "NJ" },
  { label: "New Mexico", abbreviation: "NM" },
  { label: "New York", abbreviation: "NY" },
  { label: "North Carolina", abbreviation: "NC" },
  { label: "North Dakota", abbreviation: "ND" },
  { label: "Ohio", abbreviation: "OH" },
  { label: "Oklahoma", abbreviation: "OK" },
  { label: "Oregon", abbreviation: "OR" },
  { label: "Pennsylvania", abbreviation: "PA" },
  { label: "Rhode Island", abbreviation: "RI" },
  { label: "South Carolina", abbreviation: "SC" },
  { label: "South Dakota", abbreviation: "SD" },
  { label: "Tennessee", abbreviation: "TN" },
  { label: "Texas", abbreviation: "TX" },
  { label: "Utah", abbreviation: "UT" },
  { label: "Vermont", abbreviation: "VT" },
  { label: "Virginia", abbreviation: "VA" },
  { label: "Washington", abbreviation: "WA" },
  { label: "West Virginia", abbreviation: "WV" },
  { label: "Wisconsin", abbreviation: "WI" },
  { label: "Wyoming", abbreviation: "WY" },
];

export const CameraConfig = {
  elementId: "userVideo",
  mode: "front",
  requireHD: false,
};

export const AUTH = "AUTH";
export const ELEMENT_ID = "userVideo";
export const FLOW = {
  CREATE_USER: 1,
  UPLOAD_SELFIE: 2,
  UPLOAD_DOC_FRONT: 3,
  UPLOAD_DOC_BACK: 4,
};
export const THRESHOLD = 21;
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const REQUIRES_INPUT = "REQUIRES_INPUT";
export const REMOVE_GLASSES = "Remove glasses";
export const ERROR = "error";
export const PASSKEY_BASE_URL =
  "https://api-orchestration-uatv3.cvsauth.com/v2/verification-session" ||
  "https://simplewebauthn.privateid.com";
export const ECHO = "echo";
export const TELE = "tele";
export const AUTHENTICATION_FAILED = "Authentication failed!";
export const ACCOUNT_CREATION_FAILED = "Account Creation Failed";
export const ACCOUNT_NOT_APPROVED = "Your account is not approved.";
export const UNABLE_TO_VERIFY = "Unable to Verify Credentials.";

export const ENROLL_CANVAS_RESOLUTION = { width: 1280, height: 720 };
export const IPAPI_API = "https://ipapi.co/json/";
export const PIN = "9999";
export const SWITCH_DEVICE = "Switch Device";
export const SECURITY_CHALLENGE = "security-challenge"

export const languageCodes = {
  English: "en",
  "English (British)": "en-GB",
  български: "bg",
  hrvatski: "hr",
  čeština: "cs",
  dansk: "da",
  Nederlands: "nl",
  "eesti keel": "et",
  suomi: "fi",
  français: "fr",
  Deutsch: "de",
  ελληνικά: "el",
  magyar: "hu",
  Gaeilge: "ga",
  italiano: "it",
  "latviešu valoda": "lv",
  "lietuvių kalba": "lt",
  Malti: "mt",
  polski: "pl",
  português: "pt",
  română: "ro",
  slovenčina: "sk",
  slovenščina: "sl",
  español: "es",
  svenska: "sv",
  العربية: "ar",
  català: "ca",
  "español (Latinoamérica)": "es-419",
  فارسی: "fa",
  Filipino: "fil",
  हिन्दी: "hi",
  "Bahasa Indonesia": "id",
  עברית: "iw",
  日本語: "ja",
  한국어: "ko",
  norsk: "no",
  русский: "ru",
  српски: "sr",
  ไทย: "th",
  Türkçe: "tr",
  українська: "uk",
  "Tiếng Việt": "vi",
  中文: "zh",
  "中文（台灣）": "zh-TW",
};

export const pagesRoute = {
  home: "/",
  userConsent: "/user-consent",
  userInfo: "/user-info",
  switchDevice: "/switch-device",
  redirectedMobile: "/redirected-mobile",
  faceScanIntro: "/face-scan-intro",
  faceScan: "/face-scan",
  docSelection: "/doc-selection",
};

export const loginOptions = [
  "passkey",
  "loginPin",
  "loginDl",
  "loginVc",
  "checkoutVc",
  'faceLogin'
];

export const FooterItems = [
  {
    id: 3,
    label: " Privacy",
    link: "https://github.com/openinfer/PrivateIdentity/wiki/Privacy-Policy",
  },
  {
    id: 2,
    label: "Terms",
    link: "https://github.com/openinfer/PrivateIdentity/wiki/Terms-of-Use",
  },
  {
    id: 1,
    label: "Feedback",
    link: null,
  },
];

export const EQUIFAX_FLOWS = ["Workforce", "Consumer"];

export const Workforce = [
  {
    title: "Register",
    value: "register",
    enable: true,
    type: EQUIFAX_FLOWS[0],
  },
  {
    title: "Login with face scan",
    value: "faceLogin",
    enable: true,
    type: EQUIFAX_FLOWS[0],
  },
  {
    title: "Login with pin",
    value: "loginPin",
    enable: true,
    type: EQUIFAX_FLOWS[0],
  },
  {
    title: "Login with driver's license",
    value: "loginDl",
    enable: true,
    type: EQUIFAX_FLOWS[0],
  },
  {
    title: "Check out with verifiable credentials",
    value: "checkoutVc",
    enable: true,
    type: EQUIFAX_FLOWS[0],
  },
];

export const Consumer = [
  {
    title: "Register",
    value: "register",
    enable: true,
    type: EQUIFAX_FLOWS[1],
  },
  {
    title: "Click to pay with face",
    value: "faceLogin",
    enable: true,
    type: EQUIFAX_FLOWS[1],
  },
  {
    title: "Click to pay with pin",
    value: "loginPin",
    enable: true,
    type: EQUIFAX_FLOWS[1],
  },
  {
    title: "Click to pay with driver's license",
    value: "loginDl",
    enable: true,
    type: EQUIFAX_FLOWS[1],
  },
  {
    title: "Click to pay with verifiable credentials",
    value: "checkoutVc",
    enable: true,
    type: EQUIFAX_FLOWS[1],
  },
];

export const ALL_FLOWS = [...Workforce, ...Consumer];
export const DEVEL_ORCHESTRATION = "https://api.orchestration.private.id"

export enum VerificationSessionType {
  AGE = 'AGE',
  IDENTITY = 'IDENTITY',
  AUTH = 'AUTH'
}


export const COUNTRYCODES = [
  "1",   // USA, Canada
  "91",  // India
  "44",  // UK
  "61",  // Australia
  "81",  // Japan
  "49",  // Germany
  "33",  // France
  "55",  // Brazil
  "86",  // China
  "39",  // Italy
  "7",   // Russia
];

export const PUBLIC_KEY =
  "-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAkUejlGQtY6fC/B8HY8lI\nCRZys4mQVgI62YI1POIAgcfEYXbrtKGpYJOtYcjgzZJ5Eg7VYKNEsAvfRDYDd+QX\nGqVgYiayIYeqDkgTNPnvrXLQVm/42pSWWYvOaq/DlJmLLpkQKwic45dg58VCP2A/\nzF29fjzfeofn2Im1xjvMs1NhfSd7uJAimEy8hV6MlZh03Z4iZmdl3+f6n8t/KR/Z\nwU6tKw6nbDWEuMyEMsUXkGRWyQvlX2/t5WvCx7xMX2LPlmXGhutYXJlG6rfu4PtN\nKDcGzQFySp1NuU+eV75eGiyfGwg33HRwab/jQr/FrtgphT5Q+sNUKSRZwWXDuGGd\n4wb0E1YwUwdJP0osxJ9v3g62PZD/id/Bec7TqNWhRAWhXux2jexwvwglWh497cJj\nkkLLG5QKJXGUh6S7f46y+TgGoXX0ME8nJM9jCu2OjBXqvVM17SiwU29XoYLrk2G1\nSCqqU6kivOW2cAeIDAYJtlYV5K9kPh4xYoWXKKFcLBcR35I8bdHjeMIHmp4Z+9Zm\nDfhByrDRHxIoz6cKThhX+DqrinKx928tQCaMth1S1uMQp+VDUnA4016yQVtf6XtY\nVfJzU8xt2cQ2nTSTMnwtcAET6GwFQSf17i0L5HiIZXzt685215K8tA6Oe41DsP5E\ngcyHe6R8lM7/HSI+7fn7MrUCAwEAAQ==\n-----END PUBLIC KEY-----";

export const VERIFY_STATUS_MESSAGE = {
  FAILED: "Enrollment failed--please contact Customer Service.",
  PENDING: "Enrollment not complete--pending review.",
  NULL: "Enrollment not complete--pending review.",
};
