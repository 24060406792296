import * as React from "react";
import Layout from "V2/layout";
import Camera from "V2/camera";
import useScanFrontDocument from "hooks/useScanFrontDocumentWithoutPredict";
import {
  closeCamera,
  createDocumentUploadIdTypeEnum,
  createDocumentUploadIdWithSession,
  documentImageTypeEnum,
  uploadDocumentImageWithSession,
  verifyIdWithSession,
  verifySessionTokenV2,
} from "@privateid/cryptonets-web-sdk";
import { UserContext } from "context/userContext";
import { getFrontDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import cardBg from "assets/V2/cardBg.png";
import {
  getFirstRequirement,
  getOrganizationFromURL,
  getUrlParameter,
  isMobile,
  stopCamera,
} from "utils";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { issueCredentials } from "services/vc-dock";
import {
  browserSupportsWebAuthn,
  startRegistration,
} from "@simplewebauthn/browser";
import {
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import { UAParser } from "ua-parser-js";
import { ELEMENT_ID, VERIFY_STATUS_MESSAGE } from "constant";
import { pkiEncryptData, updateUserDetails } from "@privateid/ultra-web-sdk-alpha";
import { getUserFromSession, getWebhookPayload, verifyCall } from "services/api";
import { useSearchParams } from "react-router-dom";

interface componentNameProps {}
let loaded;
type WebhookResult = {
  guid?: string;
  puid?: string;
};
const DriverLicense = (props: componentNameProps) => {
  const [doneStatus, setDoneStatus] = React.useState(false);
  const context: any = React.useContext(UserContext);
  const configuration: any = context?.configuration;
  const pageConfig: any = context?.pageConfiguration;
  const { browser } = UAParser(navigator.userAgent);
  const isMobileSafari = browser?.name === "Mobile Safari";
  const { navigateToNextPage } = useNavigation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [showCorners, setShowCorners] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const projectName = getOrganizationFromURL();
  const [supportsPasskey, setSupportsPasskey] = React.useState(false);
  const [processingLoader, setProcessingLoader] = React.useState(true);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("token");
  console.log(pageConfig, "pageConfig");

  const onSuccess = async (
    result:
      | {
          croppedDocument: string;
          croppedMugshot: string;
          inputImage: string;
          portraitConfScore: number;
        }
      | any
  ) => {
    setTimeout(() => {
      setIsCompleted(true);
      setTimeout(() => {
        setDoneStatus(true);
      }, 2000);
    }, 1000);
    const {
      inputImage,
      croppedDocument,
      croppedMugshot,
      portraitConfScore: compareScore,
    } = result;
    const documentId = await createDocumentUploadIdWithSession({
      documentType: createDocumentUploadIdTypeEnum.drivers_license,
      sessionToken: context?.tokenParams,
    });

    context.setUser({ ...context.user, documentId: documentId.id });

    const confScoreUpdatePayload = await pkiEncryptData({
      portrait_conf_score: compareScore?.toFixed(2)?.toString(),
    });

    await updateUserDetails({
      baseUrl: process.env.REACT_APP_API_URL || "",
      token: getUrlParameter("token", "") ||
        "",
      params: confScoreUpdatePayload,
    })
  
    async function uploadAllImagesAndUpdateUser() {
      const promises = [
        uploadDocumentImageWithSession({
          sessionToken: context?.tokenParams,
          documentImageType: documentImageTypeEnum.FRONT_CROPPED_DOCUMENT,
          documentId: documentId.id,
          imageString: croppedDocument,
        }),
        uploadDocumentImageWithSession({
          sessionToken: context?.tokenParams,
          documentImageType: documentImageTypeEnum.FRONT_MUGSHOT,
          documentId: documentId.id,
          imageString: croppedMugshot,
        })
      ];
  
      await Promise.all(promises);
    }

    if (configuration?.uploadToServer) {
      // Call the function to upload images and update user
     await uploadAllImagesAndUpdateUser()
        .then(() => {
          console.log("All uploads and update completed successfully.");
          setDoneStatus(true);
        })
        .catch((error) => {
          console.error("An error occurred during upload:", error);
        });
    }
    setTimeout(() => {
      if (pageConfig?.type === "passkey") {
        onGeneratePasskey();
      } else {
        onVerify();
      }
    }, 3000);
  };
  let loaded = false;

  React.useEffect(() => {
    const checkWebAuthnSupport = async () => {
      try {
        if ("PublicKeyCredential" in window) {
          const supportsPasskey = browserSupportsWebAuthn();
          setSupportsPasskey(supportsPasskey);
          if (!loaded) {
            loaded = true;
            if (context?.configuration?.isKantara) {
              // onAuthenticatePasskey(supportsPasskey);
            }
          }
        } else {
          // WebAuthn is not supported
          setSupportsPasskey(false);
        }
      } catch (error) {
        console.error("Error checking WebAuthn support:", error);
        setSupportsPasskey(false);
      }
    };

    checkWebAuthnSupport();
  }, []);
  const onGeneratePasskey = async () => {
    if (!context?.configuration?.isKantara) {
      if (!supportsPasskey) {
        context.setSuccessMessage("Success! Your account is created");
        onVerify();
        return;
      }
    }
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      onVerify();
      return;
    }
    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      onVerify();
      return;
    }
    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      onVerify();
      context.setSuccessMessage("Success! Your account is created");
    }
  };


  const goNext = async () => {
    const verifyResult: any = await verifyCall(getUrlParameter("token", "") ||
      ""
    );

    console.log("verifyResult?", verifyResult);
    if (verifyResult?.userApproved === "true") {
      if (verifyResult?.status === "pending") {
        context?.setSuccessMessage(VERIFY_STATUS_MESSAGE.PENDING);
      } else if (verifyResult?.status === "failed") {
        context?.setSuccessMessage(VERIFY_STATUS_MESSAGE.FAILED);
      } else if (verifyResult?.status === null || verifyResult?.status === "") {
        context?.setSuccessMessage(VERIFY_STATUS_MESSAGE.NULL);
      }
      getWebhookPayload(sessionId!).then((res) => {
        sessionStorage.setItem("user", JSON.stringify({ puid: res?.puid, username: res?.identityInformation?.firstName + ' ' + res?.identityInformation?.lastName }));
      });
      issueVC(sessionId, true)
      // const data: any = await getUserFromSession(context.tokenParams);
      // if (data?.user?._id) {
      //   issueVC(data?.user?._id, true);
      // }
      navigateToNextPage();
    } else {
      console.log("Navigate???");
      navigateToNextPage("", "/v2/failure-screen", "/v2/otp-screen");
    }
  };

  const onVerify = async () => {
    await closeCamera(ELEMENT_ID);
    await stopCamera();
    loaded = true;
    goNext()
    // await verifyIdWithSession({
    //   sessionToken: context?.tokenParams,
    // });
    // const verifyTokenRes = await verifySessionTokenV2({
    //   sessionToken: context?.tokenParams,
    // });
    // enum tokenStatus {
    //   PENDING = "PENDING",
    //   SUCCESS = "SUCCESS",
    //   FAILURE = "FAILURE",
    //   REQUIRES_INPUT = "REQUIRES_INPUT",
    // }
    // if (verifyTokenRes.status === tokenStatus.SUCCESS) {
    //   // Success
    //   loaded = false;
    //   localStorage.setItem("user", JSON.stringify(verifyTokenRes));
    //   context?.setV2SuccessMessage(
    //     `You are now registered.`
    //   );
    //   issueVC(verifyTokenRes.user, true);
    //   setProcessingLoader(false);
    //   setTimeout(() => {
    //     navigateToNextPage();
    //   }, 2000)
    // } else if (verifyTokenRes.status === tokenStatus.FAILURE) {
    //   setProcessingLoader(false);
    //   loaded = false;
    //   if (pageConfig) {
    //     navigateToNextPage("", "/v2/failure-screen", "/v2/otp-screen");
    //   } else {
    //     navigateWithQueryParams("/v2/failure-screen");
    //   }
    // } else if (verifyTokenRes.status === tokenStatus.REQUIRES_INPUT) {
    //   setProcessingLoader(false);
    //   getRequirements(verifyTokenRes?.dueRequirements);
    // } else if (verifyTokenRes.status === tokenStatus.PENDING) {
    //   setProcessingLoader(false);
    //   loaded = false;
    //   if (pageConfig) {
    //     navigateToNextPage("", "/v2/failure-screen", "/v2/otp-screen");
    //   } else {
    //     navigateWithQueryParams("/v2/failure-screen");
    //   }
    // }
  };

  const getRequirements = async (requirement: any) => {
    const requirementStep = await getFirstRequirement(requirement, context);
    loaded = false;
    switch (requirementStep) {
      case "requestSSN9":
        return pageConfig
          ? navigateToNextPage("", "/ssn", "/v2/otp-screen")
          : navigateWithQueryParams("/ssn");
      case "requireResAddress":
        return pageConfig
          ? navigateToNextPage("", "/address", "/v2/otp-screen")
          : navigateWithQueryParams("/address");
      case "requestScanID":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-intro", "/v2/otp-screen")
          : navigateWithQueryParams("/drivers-licence-intro");
      default:
        break;
    }
  };

  const issueVC = async (token: any, fullInformation: boolean) => {
    try {
      await issueCredentials(token, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };
  const { doScanFrontDocument, resultStatus, scanStatus } = useScanFrontDocument(
    onSuccess,
    () => {},
    context?.user?.enrollEmbeddings,
    // "/v2/scan-face"
  ) as any;
  const onCameraReady = () => {
    setShowCorners(true);
    doScanFrontDocument();
  };
  const completed = resultStatus === 0;
  return (
    <>
      <Layout
        camera
        className={`${isMobile && "frontDlAnimation"}`}
        innerContainer="max-md:mt-[-78px] max-md:!h-[calc(100vh_-_0rem)]"
      >
        <div className="px-[40px] min-h-full flex flex-col justify-between relative max-md:pt-[40px]">
          {showCorners && (
            <div
              className={`recognition-box1  relative z-10  ${
                completed ? "!w-[50%] !h-[29%]" : "!w-[89%]"
              }`}
            >
              <div
                className={`corner top-left white-border ${
                  isCompleted ? "greenBorder" : ""
                }`}
              ></div>
              <div
                className={`corner top-right  white-border ${
                  isCompleted ? "greenBorder" : ""
                }`}
              ></div>
              <div
                className={`corner bottom-left  white-border ${
                  isCompleted ? "greenBorder" : ""
                }`}
              ></div>
              <div
                className={`corner bottom-right  white-border ${
                  isCompleted ? "greenBorder" : ""
                }`}
              ></div>
            </div>
          )}
          <Camera
            onCameraReady={onCameraReady}
            progress={completed ? 100 : 0}
            frontDl={true}
            message={
              completed
                ? "Processing hold still"
                : scanStatus ||
                  getFrontDocumentStatusMessage(resultStatus) ||
                  "Position front of ID in the frame"
            }
            documentScan={true}
            subHeading={" "}
          />
          {doneStatus && (
            <div
              className={`${
                doneStatus
                  ? `absolute top-0 left-0 w-full flex flex-col items-center justify-center h-[705px] z-[9999999999] backdrop-blur-lg ${
                      isMobileSafari
                        ? "max-md:h-[calc(100vh_-_4rem)]"
                        : "max-md:h-[calc(100vh_-_2rem)]"
                    }  ${
                      window.innerWidth < 767
                        ? isMobileSafari
                          ? "[@media_((min-height:700px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                          : "[@media_((min-height:700px)_and_(max-height:730px))]:h-[calc(100vh_-_0rem)] [@media_((min-height:731px)_and_(max-height:750px))]:h-[calc(100vh_-_10rem)] [@media_((min-height:751px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                        : ""
                    }`
                  : ""
              }`}
            >
              <div
                className="bg-[#161B25B2] w-[100%] h-[100%] flex items-center justify-center successAnimationNewv2"
                style={{
                  // backgroundImage: `url(${cardBg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="text-[25px] absolute top-[62px] text-center left-[0px] z-[99999999] w-full bg-transparent bg-opacity-70 text-[#cbd4eb] py-2 rounded-lg">
                  {processingLoader ? (
                    "Processing ..."
                  ) : (
                    <>
                      <p>🎉 Done!</p>
                      <p className="text-[20px] mt-[10px]">Image Deleted.</p>
                    </>
                  )}
                </div>
                {processingLoader ? (
                  <span className="processingLoader"></span>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-success"
                    viewBox="0 0 24 24"
                  >
                    <g
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                    >
                      <circle
                        className="success-circle-outline"
                        cx="12"
                        cy="12"
                        r="11.5"
                      />
                      <circle
                        className="success-circle-fill"
                        cx="12"
                        cy="12"
                        r="11.5"
                      />
                      <polyline
                        className="success-tick"
                        points="17,8.5 9.5,15.5 7,13"
                      />
                    </g>
                  </svg>
                )}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default DriverLicense;
