import { useContext, useEffect, useState } from "react";
import useWasm from "hooks/useWasm";
import { getOrganizationFromURL, getUrlParameter, isMobile, stopCamera } from "utils";
import { CameraConfig, ELEMENT_ID, ENROLL_CANVAS_RESOLUTION, PUBLIC_KEY } from "constant";
import {
  documentImageTypeEnum,
  uploadDocumentImageWithSession,
  verifyIdWithSession,
  verifySessionTokenV2,
  closeCamera,
} from "@privateid/cryptonets-web-sdk";
import useCamera from "hooks/useCamera";
import useCameraPermissions from "hooks/useCameraPermissions";
import ProveLogo from "assets/proveLogo.png";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import FaceAnimation from "common/animateCircle/faceScanAnimation";
import { getBackDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import Lottie from "lottie-react";
import completedCheck from "Animations/4-Capture-successfully/JSON/confetti.json";
import backDlImage from "assets/backDl.svg";
import useScanBackDocument from "hooks/useScanBackDocument";
import {
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import { issueCredentials } from "services/vc-dock";
import { startRegistration } from "@simplewebauthn/browser";

const rendererSettings = {
  preserveAspectRatio: "xMaxYMin slice",
};
let startRegisterFlow = false;
const BackDl = ({ setStep }: { setStep: (e: number) => void }) => {
  const context: any = useContext(UserContext);
  const [deviceId, setDeviceId] = useState<string>();
  const [phone, setPhone] = useState<string>("");
  const [showPhone, setShowPhone] = useState(false);
  const { isCameraGranted }: any = useCameraPermissions(() => {});
  const { navigateToNextPage } = useNavigation();
  const [minimizeCamera, setMinimizeCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isCompletedPredict, setIsCompletedPredict] = useState(false);
  const [overlay, setOverlay] = useState(true);

  // Camera and Wasm init
  const onCameraFail = () => {
    //   cameraFail?.();
  };
  const cameraReady = () => {
    //   onCameraReady?.();
    onCameraReady();
  };
  const documentScan = true;
  const url = process.env.REACT_APP_WASM_API_URL || "";
  const { ready: wasmReady, wasmStatus } = useWasm(
    getUrlParameter("token", "") || "",
    url,
    PUBLIC_KEY
  );
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );
  useEffect(() => {
    if (device) {
      setDeviceId(device);
    }
  }, [device]);
  console.log(wasmStatus, "wasmStatus");

  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };
  useEffect(() => {
    handleWasmLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus]);
  const onStatus = () => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };

  useEffect(() => {
    if (isCompletedPredict && !showPhone) {
      setCompleted(true);
      // onDocumentSuccess();
    }
  }, [isCompletedPredict, showPhone]);

  useEffect(() => {
    if (phone?.length >= 12) {
      setShowPhone(false);
    }
  }, [phone]);

  const handleAnimationComplete = (state: string) => {
    if (state === "start") {
      // setStartAnimation(true);
    } else if (state === "completed") {
      setCompleted(true);
      // onDocumentSuccess();
    }
  };

  useEffect(() => {
    if (completed) {
      if (window.location.pathname === "/register-mobile-with-id") {
        // onGeneratePasskey();
        setTimeout(() => {
          setStep(6);
        }, 3000);
      } else {
        setTimeout(() => {
          navigateToNextPage("", "/waiting", "/prove-register");
        }, 3000);
      }
    }
  }, [completed]);

  const onVerify = async () => {
    await closeCamera(ELEMENT_ID);
    await stopCamera();
    await verifyIdWithSession({
      sessionToken: context?.tokenParams,
    });

    const verifyTokenRes = await verifySessionTokenV2({
      sessionToken: context?.tokenParams,
    });

    enum tokenStatus {
      PENDING = "PENDING",
      SUCCESS = "SUCCESS",
      FAILURE = "FAILURE",
      REQUIRES_INPUT = "REQUIRES_INPUT",
    }
    startRegisterFlow = false;
    if (verifyTokenRes.status === tokenStatus.SUCCESS) {
      // Success
      context.setSuccessMessage("Success! Your account is created");
      await issueVC(verifyTokenRes.user, true);
      setStep(6);
    } else if (verifyTokenRes.status === tokenStatus.FAILURE) {
      context.setSuccessMessage("Success! Your account is created");
      await issueVC(verifyTokenRes.user, true);
    } else if (verifyTokenRes.status === tokenStatus.REQUIRES_INPUT) {
      // Handle requires input
    } else if (verifyTokenRes.status === tokenStatus.PENDING) {
      // Handle pending status
    }
  };

  const issueVC = async (userId: string, fullInformation: boolean) => {
    try {
      await issueCredentials(userId, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };

  const onGeneratePasskey = async () => {
    if (startRegisterFlow) return;
    startRegisterFlow = true;
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;

    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      onVerify();
      return;
    }

    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      onVerify();
      return;
    }

    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      onVerify();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setOverlay(false);
    }, 3000);
  }, []);

  const onSuccess = async (result: any) => {
    await closeCamera(ELEMENT_ID);
    await stopCamera();
    const { barcodeData, croppedDocument, croppedBarcode } = result;
    console.log(result, "result129");

    setCompleted(true);
    setIsCompletedPredict(true);
    context.setUser({ ...context.user, barcodeData });
    async function uploadDocumentAndUpdateImages() {
      const promises = [];
      if (croppedDocument) {
        promises.push(
          uploadDocumentImageWithSession({
            sessionToken: context?.tokenParams,
            documentImageType: documentImageTypeEnum.BACK_CROPPED_DOCUMENT,
            documentId: context?.user?.documentId,
            imageString: croppedDocument,
          })
        );
      }
      if (croppedBarcode) {
        promises.push(
          uploadDocumentImageWithSession({
            sessionToken: context?.tokenParams,
            documentImageType: documentImageTypeEnum.BACK_BARCODE,
            documentId: context?.user?.documentId,
            imageString: croppedBarcode,
          })
        );
      }

      await Promise.all(promises);
    }
    if (context?.configuration?.uploadToServer) {
      await uploadDocumentAndUpdateImages()
        .then(() => {
          console.log("All uploads and updates completed successfully.");
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
    const backDocumentData = {
      firstName: barcodeData?.barcode_data?.firstName,
      lastName: barcodeData?.barcode_data?.lastName,
      dob: barcodeData?.barcode_data?.dateOfBirth,
      address: {
        addressLine1: barcodeData?.barcode_data?.streetAddress1,
        addressLine2: barcodeData?.barcode_data?.streetAddress2,
        city: barcodeData?.barcode_data?.city,
        state: barcodeData?.barcode_data?.state,
        zipCode: barcodeData?.barcode_data?.postCode,
        country: barcodeData?.barcode_data?.issuingCountry,
        idDocumentNumber: barcodeData?.barcode_data?.customerId,
      },
    };

    context.setUser({ ...context.user, backDocumentData });
  };
  const onDocumentSuccess = () => {
    if (window.location.pathname === "/register-mobile-with-id") {
      setTimeout(() => {
        setStep(6);
      }, 3000);
      // onGeneratePasskey();
    } else {
      setTimeout(() => {
        navigateToNextPage("", "/waiting", "/prove-register");
      }, 2000);
    }
  };

  const { scanBackDocument, barcodeStatusCode } = useScanBackDocument(
    onSuccess
  ) as any;
  const onCameraReady = () => {
    scanBackDocument();
  };

  const status =
    barcodeStatusCode === 0
      ? "Success"
      : getBackDocumentStatusMessage(barcodeStatusCode);

  return (
    <>
      <div className="bg-[#000] w-[100%] absolute top-[0px] left-[0px] max-md:w-[100%] h-[100vh] max-md:top-[0px] max-md:h-[calc(100vh_-80px)] flex flex-col items-center justify-center">
        <img
          src={ProveLogo}
          alt="ProveLogo"
          className="absolute top-[10px] z-[99999] max-md:right-[40%] right-[unset] left-[40%] max-md:left-[unset]"
        />

        {completed ? (
          <div className="absolute h-full w-full top-0 bg-[#0c121c] flex items-center justify-center">
            <Lottie
              loop={false}
              autoplay={true}
              animationData={completedCheck}
              style={{
                height: isMobile ? 320 : "260px",
              }}
              rendererSettings={isMobile ? {} : rendererSettings}
            />
          </div>
        ) : (
          <>
            <FaceAnimation
              isCircle={minimizeCamera}
              isScanned={completed}
              handleAnimationComplete={handleAnimationComplete}
              fullScreen={true}
            >
              <div className="text-[30px] absolute top-16 w-full text-white text-center z-[999999999] py-2 rounded-lg">
                {status || "Back of driver’s license"}
              </div>
              <p className="text-[14px] absolute top-[7rem] w-full text-white text-center z-[999999999] py-2 rounded-lg">
                Position back of license in the frame
              </p>
              <div className="camera-container">
                {/* Overlay for face recognition */}
                <div className="camera-overlay">
                  {/* Five dots on the face */}
                  {/* Face recognition box */}
                  <div className="recognition-box">
                    {/* Animated corners */}
                    <div className="corner top-left white-border"></div>
                    <div className="corner top-right  white-border"></div>
                    <div className="corner bottom-left  white-border"></div>
                    <div className="corner bottom-right  white-border"></div>
                  </div>
                </div>
              </div>
              {overlay && (
                <div className="w-full h-full flex z-[999999999] items-center justify-center absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                  <img
                    src={backDlImage}
                    alt="dl_image"
                    className="w-[70%] filter invert-[100%]"
                  />
                </div>
              )}
              <video
                id="userVideo"
                muted
                autoPlay
                playsInline
                className={`w-full h-full object-cover`}
              />
            </FaceAnimation>
          </>
        )}
      </div>
    </>
  );
};

export default BackDl;
