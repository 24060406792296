/* eslint-disable @typescript-eslint/no-use-before-define */
import { SetStateAction, useEffect, useState } from "react";
import {
  compareEmbeddings,
  scanFrontDocument,
} from "@privateid/ultra-web-sdk-alpha";
import { CANVAS_SIZE } from "../utils";
import Rerun from "../utils/reRuncheck";
import { useToast } from "components/ui/use-toast";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import {
  getFrontDocumentStatusMessage,
} from "@privateid/ultra-web-sdk-alpha/dist/utils";

const useFrontDocumentScan = (
  onSuccess: ({
    croppedDocument,
    inputImage,
    croppedMugshot,
    portraitConfScore,
  }: {
    croppedDocument: string | null;
    inputImage: string | null;
    croppedMugshot: string | null;
    portraitConfScore: number;
  }) => void,
  onFailCallback: ({
    status,
    message,
  }: {
    status: string;
    message: string;
  }) => void,
  enrollEmbeddings: string
) => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { toast } = useToast();
  const [isFound, setIsFound] = useState(false);
  const [resultStatus, setResultStatus] = useState(null);
  const [scanStatus, setScanStatus] = useState("");
  console.log(enrollEmbeddings,'enrollEmbeddings123');
  
  // base64 image
  const [croppedDocumentBase64, setCroppedDocumentBase64] = useState(null);
  const [croppedMugshotBase64, setCroppedMugshotBase64] = useState(null);

  const [documentEmbeddings, setDocumentEmbeddings] = useState(null);

  // confidence value
  const [resultResponse, setResultResponse] = useState(null);
  const [returnValue, setResultValue] = useState<any>({});

  const documentCallback = (result: any) => {
    console.log("front scan callback: ", result);
    RerunAction.RerunAction = false;
    setResultResponse(result);
    setResultStatus(result?.doc_validation_status);
    if (
      result?.doc_validation_status === 0 ||
      result?.doc_validation_status === 10
    ) {
      RerunAction.clearCheck();

      if (
        result?.cropped_document &&
        result?.cropped_mugshot &&
        result?.embedding
      ) {
        setIsFound(true);
        setCroppedMugshotBase64(result?.cropped_mugshot);
        setDocumentEmbeddings(result?.embedding);
        setCroppedDocumentBase64(result?.cropped_document);
        setResultValue(result);
        setScanStatus("");
      } else {
        doScanFrontDocument();
        setScanStatus("");
      }
    } else {
      onFailCallback({
        status: result?.doc_validation_status.toString(),
        message: getFrontDocumentStatusMessage(result?.doc_validation_status),
      });
      doScanFrontDocument();
    }
  };


  //   // if all images are available and Document UUID available call onSuccess Callback
  useEffect(() => {
    if (
      isFound &&
      croppedDocumentBase64 &&
      croppedMugshotBase64 &&
      documentEmbeddings
    ) {
      console.log("croppedDocument data?", {
        documentEmbeddings,
        enrollEmbeddings,
      });

      const compareCallback = async (result:any) => {
        console.log("compare callback:", result);

        if(result?.call_status === 0){
          onSuccess({
            croppedDocument: "data:image/png;base64," + croppedDocumentBase64,
            croppedMugshot: "data:image/png;base64," + croppedMugshotBase64,
            portraitConfScore: result?.confidence_score,
            inputImage: ""
          });
        } else{
          doScanFrontDocument();
        }
      }

      const doCompare = async () => {

        console.log("start compare !!!", {documentEmbeddings, enrollEmbeddings});
        const score = await compareEmbeddings(
          documentEmbeddings,
          enrollEmbeddings,
          {},
          compareCallback,
        );
      };

      if (enrollEmbeddings) {
        doCompare();
      } else {
        toast({
          variant: "destructive",
          description: "Encrypted Embeddings not found. Please scan your face again.",
        });
        navigateWithQueryParams("/v2/scan-face");
      }
    }
  }, [
    isFound,
    croppedDocumentBase64,
    croppedMugshotBase64,
    documentEmbeddings,
  ]);

  const doScanFrontDocument = async (
    canvasSize?: any,
    initializeCanvas?: any
  ) => {
    // RerunAction.doInterval();
    const canvasObj = canvasSize ? CANVAS_SIZE?.[canvasSize] : {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await scanFrontDocument({
      callback: documentCallback,
      config: {
        input_image_format: "rgba",
        document_auto_rotation: true,
      },
    });
  };

  const RerunAction = new Rerun(doScanFrontDocument);

  const reScanFrontDocument = () => {
    setCroppedDocumentBase64(null);
    setCroppedMugshotBase64(null);
    setResultValue({});
    doScanFrontDocument();
  };

  return {
    doScanFrontDocument,
    isFound,
    setIsFound,
    resultStatus,
    resultResponse,
    reScanFrontDocument,
    scanStatus,
  };
};

export default useFrontDocumentScan;